$(document).foundation();

function show_dropDown() {
document.getElementById('drop-down').style.display="block";
}

function hide_dropDown() {
document.getElementById('drop-down').style.display="none";
}

function toggleSidebar() {
$("#site_sidebar").toggle();
}